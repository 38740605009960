import { GameStats } from '../../lib/localStorage'
import { BaseModal } from './BaseModal'
import img from './mappp.png'

type Props = {
  isOpen: boolean
  handleClose: () => void
  solution: string
  guesses: string[]
  gameStats: GameStats
  isLatestGame: boolean
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  handleShareFailure: () => void
  handleMigrateStatsButton: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
}

export const StatsModal = ({ isOpen, handleClose, isGameWon }: Props) => {
  if (!isGameWon) {
    return (
      <BaseModal
        title="Todavía no ganaste nada"
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <></>
      </BaseModal>
    )
  }
  return (
    <BaseModal title="TARTA" isOpen={isOpen} handleClose={handleClose}>
      <img src={img} alt="PISTA" />
    </BaseModal>
  )
}
